@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 92vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.PhoneInputCountry {
  display: flex;
  height: auto;
  height: 20px !important;
}
/* Define scrollbar styles */
::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #293845;
  /* Slightly darker shade of body color */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #747474;
  /* Slightly darker shade of navbar color */
  border-radius: 6px;
  /* Roundness of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #636363;
  /* Slightly darker shade for hover effect */
}